<template>
  <div class="job-container">
    <div class="g-section mb-20">
      <div class="g-wrap flex-column p-20"> 
        <div class="sel-condition">
          <div>
            <span class="sel-condition-title">当前订单意向院校列表</span> 
            <div class="sel-condition-wrap ">
            </div>
          </div>
          <el-button type="primary" @click="$router.go(-1)" size="small" icon="el-icon-arrow-left">返回</el-button>
        </div>
        <div class="talent-list">

          <div class="talent-item" v-for="(item,i) in listSchool" :key="i">
            <div class="item-row">
              <div class="row-left"><span>{{item.Name}}</span></div>
              <div class="row-right"><span>申请时间：2020-12-30</span></div>
            </div>
            <div class="item-row mt-10 mb-10">
              <div class="row-left">Logo</div>
              <div class="row-right">
                <el-button type="primary" size="small" plain @click="dialogVisible=true">查看</el-button>
                <el-button type="primary" size="small" plain @click="$router.push('/enterprise/chat')">沟通</el-button>
                <el-button type="primary" size="small" plain>签订协议</el-button>
              </div>
            </div>
            <div class="item-row">
              <div class="row-left"><span>我是院校介绍</span></div>
              <div class="row-right">
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="XXX学校的信息" :visible.sync="dialogVisible" width="980px" :before-close="handleClose">

      <div class="dialog-content">
        <span class="content-title">基本信息</span>
        <span class="content-title">资质信息</span>
        <span class="content-title">申请说明</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchZone: '1',
      searchKeyword: '',
      dialogVisible: false,
      searchArea: [],
      optionsArea: [{
        value: 'gd',
        label: '广东',
        children: [{
          value: 'sz',
          label: '深圳',
          children: [{
            value: 'lh',
            label: '龙华'
          }, {
            value: 'ns',
            label: '南山'
          }, {
            value: 'ft',
            label: '福田'
          }, {
            value: 'lg',
            label: '龙岗'
          }]
        }, {
          value: 'gz',
          label: '广州',
          children: [{
            value: 'by',
            label: '白云'
          }, {
            value: 'th',
            label: '天河'
          }]
        }]
      }, {
        value: 'hn',
        label: '湖南',
        children: [{
          value: 'ch',
          label: '长沙',
          children: [
            {
              value: 'qb',
              label: '全部'
            }
          ]
        }]
      }],
      optionsIndustry: [],
      listSchool: [
        { Id: '1', Logo: '', Name: '学校1' },
        { Id: '2', Logo: '', Name: '学校2' },
        { Id: '3', Logo: '', Name: '学校3' },
        { Id: '4', Logo: '', Name: '学校4' },
        { Id: '5', Logo: '', Name: '学校5' },
        { Id: '6', Logo: '', Name: '学校6' },
        { Id: '7', Logo: '', Name: '学校7' },
        { Id: '8', Logo: '', Name: '学校8' }
      ],
       
      isExpand: true
    }
  }
}
</script>

<style lang="scss" scoped>
.job-container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  flex: 1;
}
.job-banner {
  display: flex;
  width: 100%;
  height: 200px;
  background-color: #d3dfe8;
  justify-content: center;
  align-items: center;
}
.condition-section {
  flex-direction: column;
  padding-top: 0px;
}
.talent-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 20px;
}
.talent-item {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  cursor: pointer;
  &:hover {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
  }
}
.item-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.condition-section-hide {
  display: none;
}
.job-condition {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .job-condition-title {
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
  }
  .job-condition-wrap {
    display: flex;
    .job-condition-item {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 4px 8px;
      border-radius: 3px;
      margin-right: 10px;
      &:hover {
        background-color: #409eff;
        color: #ffffff;
      }
    }
    .checked {
      background-color: #409eff;
      color: #ffffff;
    }
  }
}
.job-condition-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sel-condition {
  border: 1px solid #b3d8ff;
  background-color: #ecf5ff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px;
  .sel-condition-title {
    color: #409eff;
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
  }
}
.job-title {
  color: #409eff;
  font-size: 18px;
}
.table-bottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon-collect {
  color: #409eff;
  font-size: 18px;
  cursor: pointer;
}
.dialog-content {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  .content-title {
    margin-bottom: 20px;
    &::before {
      content: '';
      vertical-align: -3px;
      border-radius: 2px;
      display: inline-block;
      width: 3px;
      height: 16px;
      background-color: #409ffe;
      margin-right: 5px;
    }
  }
}
</style>